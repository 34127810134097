document.addEventListener("DOMContentLoaded", function () {
    const ngpForm = document.querySelectorAll(".form-ngp-base.ngp-inline");
    var nvtag_callbacks = window.nvtag_callbacks = window.nvtag_callbacks || {};
    nvtag_callbacks.alterFormDefinition = nvtag_callbacks.alterFormDefinition || [];

    var hideDemoFormFields = function (args) {

        let formID = args.form_definition.shortCode;

        // if the form is the demo form, only show select fields
        if (formID === "5r7I2eqN-kyshSYk6ZS6Rg2") {
            let inputs = args.form_definition.form_elements[2].children;
            let newFieldSets = [];
            let submitBtn = args.form_definition.form_elements[1];
            submitBtn.value = "SUBMIT";
            for (const input of inputs) {
                if (input.name === 'FirstName') {
                    newFieldSets.push(input);
                };
                if (input.name === 'LastName') {
                    newFieldSets.push(input);
                };
                if (input.name === 'PostalCode') {
                    newFieldSets.push(input);
                };
                if (input.name === 'EmailAddress') {
                    newFieldSets.push(input);
                };
                if (input.name === 'HomePhone') {
                    newFieldSets.push(input);
                };
            };

            if (newFieldSets && newFieldSets.length > 0) {
                args.form_definition.form_elements[2].children = [];
                args.form_definition.form_elements[2].children.push(...newFieldSets);
            };
        };

        return args;

    };

    if (ngpForm) {
        nvtag_callbacks.alterFormDefinition.push(hideDemoFormFields);
    };
});